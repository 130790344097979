export const STATUS = {
  ACTIVE: true,
  INACTIVE: false,
}


export const STAGE_TYPES = {
  SPEAKING: 1,
  LISTENING: 2,
  VOCAB: 3,
  'GAME: WORD-CONNECT': 4,
  'GAME: PICTURE-WORDS': 5,
  'GAME: WORD-PRONUN': 6
}

export const VOCAB_TYPES = {
  'Matching-1-image-n-words': 1,
  'Matching-n-image-n-words': 2,
  'Matching-n-image-1-words': 3,
}
